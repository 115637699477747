import { connect } from 'react-redux';

import {
    mapDispatchToProps,
    mapStateToProps,
    ProductLinksContainer as SourceProductLinksContainer,
} from 'SourceComponent/ProductLinks/ProductLinks.container';
import LinkedProductsReducer from 'Store/LinkedProducts/LinkedProducts.reducer';
import { withReducers } from 'Util/DynamicReducer';

export {
    mapStateToProps,
    mapDispatchToProps,
};

/** @namespace Theme/Component/ProductLinks/Container */
export class ProductLinksContainer extends SourceProductLinksContainer {
    static defaultProps = {
        numberOfProductsToDisplay: 12,
        areDetailsLoaded: true,
    };
}

export default withReducers({
    LinkedProductsReducer,
})(connect(mapStateToProps, mapDispatchToProps)(ProductLinksContainer));
