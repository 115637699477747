import ChevronIcon from 'Component/ChevronIcon';
import { Directions } from 'Component/ChevronIcon/ChevronIcon.config';
import {
    ExpandableContentComponent as SourceExpandableContent,
} from 'SourceComponent/ExpandableContent/ExpandableContent.component';

/** @namespace Theme/Component/ExpandableContent/Component */
export class ExpandableContentComponent extends SourceExpandableContent {
    static defaultProps = {
        heading: '',
        isContentExpanded: false,
        onClick: null,
        children: [],
        isArrow: true,
        mods: {},
    };

    renderButtonIcon() {
        const { isContentExpanded } = this.state;
        const { isArrow } = this.props;

        if (isArrow) {
            return <ChevronIcon direction={ isContentExpanded ? Directions.TOP : Directions.BOTTOM } />;
        }

        return this.renderTogglePlusMinus();
    }
}

export default ExpandableContentComponent;
